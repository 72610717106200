import {
  Card, Box, Typography, Button,
} from '@mui/material';
import { map } from 'lodash';
import React, { useState } from 'react';
import './shared.css';
import { Star, StarBorderOutlined } from '@mui/icons-material';

function ManagerCard({ data, setSelectedTeam, selectedTeam }) {
  console.log('selectedTeam', selectedTeam);
  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'flex-start',
        m: 0.5,
        minWidth: 200,
      // backgroundColor: 'mediumseagreen',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          display: 'flex',
          // color: 'text.secondary',
          // backgroundColor: 'ActiveCaption',
          justifyContent: 'center',

          flexGrow: 1,
          backgroundColor: '#990000',
          fontWeight: 600,

        }}
      >
        {data[0].owner}

      </Typography>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        m: 0.5,
        // backgroundColor: 'ThreeDFace',
      }}
      >
        {map(data, (p) => (
          <Button
            onClick={() => setSelectedTeam(p === selectedTeam ? null : p)}
            endIcon={p === selectedTeam ? <Star /> : <StarBorderOutlined />}
          >
            {p.race}
          </Button>
        ))}
      </Box>
    </Card>
  );
}

export default ManagerCard;
