import React from 'react';
import './shared.css';
import {
  compact, intersection, isEmpty, map,
} from 'lodash';
import {
  Box, Card, TextField, Typography,
} from '@mui/material';

function Fixture({
  fixture: {
    homeTeam, homeScore, awayTeam, awayScore, isComplete,
  },
}) {
  // console.log(fixture);
  return (
    <Card sx={{
      width: 250,
      p: 0.2,
    }}
    >
      <Box sx={{
        display: 'flex',
        px: '5px',
        backgroundColor: '#990000',
        flexDirection: 'row',
        // alignItems: 'center',
        justifyContent: 'space-between',
        // backgroundColor: 'palegoldenrod',
        // m: 1,
      }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{homeTeam}</Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>VS</Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{awayTeam}</Typography>
      </Box>
      {isComplete
        ? (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            justifyContent: 'space-around',
            // backgroundColor: 'palegoldenrod',
            m: 1,
            // minWidth: { md: 350 },
          }}
          >
            <Typography variant="h4" sx={{ color: 'text.secondary' }}>{homeScore}</Typography>
            <Typography variant="h4" sx={{ color: 'text.secondary' }}>{awayScore}</Typography>
          </Box>
        ) : (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            justifyContent: 'space-around',
            backgroundColor: 'palegoldenrod',
            m: 1,
          // minWidth: { md: 350 },
          }}
          >
            <TextField id="outlined-basic" label="" variant="outlined" disabled />
            <TextField id="outlined-basic" label="" variant="outlined" disabled />
          </Box>
        )}
    </Card>
  );
}

export default Fixture;
