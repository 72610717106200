import React, { useEffect, useState } from 'react';
import './shared.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { filter, map, orderBy } from 'lodash';

import { Box, Button, Container } from '@mui/material';
import TeamRow from '../Components/TeamRow';
import Stars from '../Components/Stars';
import Rules from '../Components/Rules';
import TableRow from '../Components/TableRow';

function Table({ teams, fixtures }) {
  const rows = teams.map((team) => {
    const completedMatchs = orderBy(
      map(
        filter(fixtures, (fixture) => fixture.isComplete),
        (fixture) => {
          try {
            // console.log(fixture)
            return {
              ...fixture,
              updated: fixture.updated.toDate(),
            };
          } catch (error) {
            return console.log('error', fixture);
          }
        },
      ),
      ['updated', 'asc'],
    );

    const filteredFixtures = filter(
      completedMatchs,
      (fixture) => fixture.homeTeam === team.race || fixture.awayTeam === team.race,
    );
    console.log('filteredFixtures', JSON.stringify(filteredFixtures, null, 2));
    // console.log('team', team);
    const homeWins = filteredFixtures.reduce(
      (resultsCount, currentValue) => {
        console.log('currentValue', currentValue);

        const teamHomeWin = currentValue.homeTeam === team.race
          ? currentValue.homeScore > currentValue.awayScore
          : false;

        const teamAwayWin = currentValue.awayTeam === team.race
          ? currentValue.awayScore > currentValue.homeScore
          : false;

        const teamDraw = currentValue.homeScore === currentValue.awayScore;

        const teamHomeLoss = currentValue.homeTeam === team.race
          ? currentValue.homeScore < currentValue.awayScore
          : false;

        const teamAwayLoss = currentValue.awayTeam === team.race
          ? currentValue.awayScore < currentValue.homeScore
          : false;

        const teamHomeGoals = currentValue.homeTeam === team.race ? currentValue.homeScore : 0;

        const teamAwayGoals = currentValue.awayTeam === team.race ? currentValue.awayScore : 0;

        const teamHomeGoalsAgainst = currentValue.homeTeam !== team.race ? currentValue.homeScore : 0;

        const teamAwayGoalsAgainst = currentValue.awayTeam !== team.race ? currentValue.awayScore : 0;

        const pts = teamHomeWin || teamAwayWin ? 3 : teamDraw ? 1 : 0;

        return {
          ...resultsCount,
          played: resultsCount.played + 1,
          win:
              teamHomeWin || teamAwayWin
                ? resultsCount.win + 1
                : resultsCount.win,
          draw: teamDraw ? resultsCount.draw + 1 : resultsCount.draw,
          loss:
              teamHomeLoss || teamAwayLoss
                ? resultsCount.loss + 1
                : resultsCount.loss,
          goalsFor: resultsCount.goalsFor + teamAwayGoals + teamHomeGoals,
          goalsAgainst:
              resultsCount.goalsAgainst
              + teamHomeGoalsAgainst
              + teamAwayGoalsAgainst,
          goalDiff:
              resultsCount.goalDiff
              + (teamAwayGoals + teamHomeGoals)
              - (teamHomeGoalsAgainst + teamAwayGoalsAgainst),
          pts: resultsCount.pts + pts,
        };
      },
      {
        race: team.race,
        played: 0,
        win: 0,
        loss: 0,
        draw: 0,
        goalsFor: 0,
        goalsAgainst: 0,
        goalDiff: 0,
        pts: 0,
      },
    );

    console.log('homeWins', JSON.stringify(homeWins, null, 2));

    return homeWins;
  });

  return (
    <>
      <h1>
        Table
      </h1>

      {map(orderBy(
        rows,
        ['pts', 'goalDiff', 'goalsFor'],
        ['desc', 'desc', 'desc'],
      ), (row) => <TableRow row={row} />)}
    </>
  );
}

export default Table;
