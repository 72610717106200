/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import './shared.css';
import {
  filter, findIndex, orderBy, map, isEmpty, reverse, find,
} from 'lodash';
import { Stack, Box } from '@mui/material';
import Fixture from '../Components/Fixture';

function Fixtures({ teams, fixtures }) {
  const [tableData, setTableData] = useState([]);

  const orderBot = () => {
    const nullsCheck = findIndex(fixtures, (fixture) => fixture.updated === null);
    if (nullsCheck !== -1) {
      return [];
    }

    const completedMatchs = orderBy(
      map(
        filter(fixtures, (fixture) => fixture.isComplete),
        (fixture) => {
          try {
            // console.log(fixture)
            return {
              ...fixture,
              updated: fixture.updated.toDate(),
            };
          } catch (error) {
            return console.log('error', fixture);
          }
        },
      ),
      ['updated', 'asc'],
    );

    let remainingMatchs = orderBy(
      filter(fixtures, (fixture) => !fixture.isComplete),
      ['matchWeight', 'asc'],
    );
    // console.log('remainingMatchs', remainingMatchs.length)
    // console.log(
    //   'completedMatchs away',
    //   completedMatchs[completedMatchs.length - 1].awayOwner,
    // )
    const playerRotation = [
      { home: 'Max', away: 'Ash' },
      { home: 'Max', away: 'John' },
      { home: 'Ash', away: 'John' },
      { home: 'Ash', away: 'Max' },
      { home: 'John', away: 'Max' },
      { home: 'John', away: 'Ash' },
    ];

    let lastGame = -1;
    if (!isEmpty(completedMatchs)) {
      // console.log('lastGame obj', {
      //   home: completedMatchs[completedMatchs.length - 1].homeOwner,
      //   away: completedMatchs[completedMatchs.length - 1].awayOwner,
      // })

      // eslint-disable-next-line max-len
      lastGame = findIndex(playerRotation, (set) => set.home === completedMatchs[completedMatchs.length - 1].homeOwner
          && set.away === completedMatchs[completedMatchs.length - 1].awayOwner);

      // console.log('lastGame player index: ', `${lastGame} ${playerRotation[lastGame].home} vs ${playerRotation[lastGame].away}`);
    }
    // console.log('remainingMatchs.length: ', remainingMatchs.length)

    const sortedRemainingMatchs = [];
    let count = lastGame;
    const sortRemainingMatchs = () => {
      while (remainingMatchs.length > 0) {
        if (count === 5) {
          // console.log('count too high', count)
          count = -1;
        } else {
          count += 1;
          // console.log('looking for match is count: ', count)
          // console.log('remainingMatchs.length: ', remainingMatchs.length)
          // console.log(
          //   'sortedRemainingMatchs.length: ',
          //   sortedRemainingMatchs.length,
          // )

          // const match = find(remainingMatchs, currentMatch => {
          //   // console.log('playerRotation[count]', playerRotation[count].home)
          //   // console.log('currentMatch.homeOwner', currentMatch.homeOwner)
          //   return (
          //     playerRotation[count].home === currentMatch.homeOwner &&
          //     playerRotation[count].away === currentMatch.awayOwner
          //   )
          // })
          // console.log(
          //   'match: ',
          //   match,
          // )

          // const possiableMatches = filter(
          //   remainingMatchs,
          //   (currentMatch) => playerRotation[count].home === currentMatch.homeOwner
          //       && playerRotation[count].away === currentMatch.awayOwner,
          // );
          console.log('remainingMatchs.length: ', remainingMatchs.length);
          // console.log('sortedRemainingMatchs.length: ', sortedRemainingMatchs.length)

          if (sortedRemainingMatchs.length === 0) {
            console.log(
              'last completed match: ',
              `${completedMatchs[completedMatchs.length - 1].homeTeam} - ${completedMatchs[completedMatchs.length - 1].awayTeam}`,
            );
          } else {
            console.log(
              'last sorted match: ',
              sortedRemainingMatchs[sortedRemainingMatchs.length - 1],
            );
          }

          // const lastCurrentPlayerMatches = filter(
          //   [...completedMatchs, ...sortedRemainingMatchs],
          //   (currentMatch) => playerRotation[count].home === currentMatch.homeOwner,
          // );
          // console.log(
          //   'lastCurrentPlayerMatches: ',
          //   lastCurrentPlayerMatches,
          // )
          // lastCurrentPlayerMatches.forEach(match => {
          //   console.log(
          //     `${match.homeTeam} - ${match.awayTeam} Played ${match.isComplete}`
          //   )
          // });
          console.log(
            'playerRotation[count].home: ',
            playerRotation[count].home,
          );

          // console.log(
          //   `${playerRotation[count].home}'s last 2 teams: `,
          //   'TODO://',
          // )

          const leastRecentTeam = (player) => {
            const matches = reverse([...completedMatchs, ...sortedRemainingMatchs]);
            const playerTeams = filter(
              teams,
              (team) => team.owner === player,
            );
            // console.log(
            //   `${player}'s  teams: `,
            //   playerTeams,
            // )
            // eslint-disable-next-line max-len
            const gameIndex = (race) => findIndex(matches, ({ awayTeam, homeTeam }) => awayTeam === race || homeTeam === race);

            // const idx = gameIndex
            // console.log(
            //   `${playerTeams[0].race}s index: `,
            //   idx
            // )
            const leastRecent = { team: {}, idx: -1 };
            playerTeams.forEach((team) => {
              const idx = gameIndex(team.race);
              console.log(`${team.race}'s index ${idx}`);
              if (idx > leastRecent.idx) {
                leastRecent.team = team;
                leastRecent.idx = idx;
              }
            });
            console.log(`homeLeastRecent: ${leastRecent.team.race} =  ${leastRecent.idx}`);
            return leastRecent.team;
          };

          const idealHome = leastRecentTeam(playerRotation[count].home);
          const idealAway = leastRecentTeam(playerRotation[count].away);
          console.log(
            'idealHome: ',
            idealHome,
          );
          console.log(
            'idealAway: ',
            idealAway,
          );
          // eslint-disable-next-line max-len
          let match = find(remainingMatchs, (currentMatch) => idealHome.race === currentMatch.homeTeam
              && idealAway.race === currentMatch.awayTeam);

          if (!match) {
            console.log('-- Back up match: ');

            match = find(remainingMatchs, (currentMatch) => idealHome.race === currentMatch.homeTeam
                && idealAway.owner === currentMatch.awayOwner);
          }

          if (!match) {
            console.log('-- -- Back up match: ');

            // eslint-disable-next-line max-len
            match = find(remainingMatchs, (currentMatch) => idealHome.owner === currentMatch.homeOwner
                && idealAway.owner === currentMatch.awayOwner);
          }

          if (!match) {
            console.log('-- -- Back up match: ');

            match = find(remainingMatchs, (currentMatch) => idealHome.owner === currentMatch.awayOwner
                && idealAway.owner === currentMatch.homeOwner);
          }
          console.log('Suggested match: ', match);
          // console.log('idealHome.race', idealHome.race)

          remainingMatchs = filter(
            remainingMatchs,
            (currentMatch) => currentMatch !== match,
          );

          // console.log('match ' + sortedRemainingMatchs.length, match)
          sortedRemainingMatchs.push(match);
          console.log(
            '-----------',
          );
        }
      }
    };
    // TODO: why are the last 2 fixtures blank????
    sortRemainingMatchs();
    // console.log('sortedRemainingMatchs', sortedRemainingMatchs.length)

    const finalFixtures = [...completedMatchs, ...sortedRemainingMatchs];

    return finalFixtures;
  };

  useEffect(() => {
    if (fixtures.length > 0) {
      setTableData(orderBot());
      // console.log('fixtures.length', fixtures.length)
    }
  }, [fixtures]);

  return (
    <Box sx={{
      m: 1,
    }}
    >
      <Stack spacing={2}>
        {map(tableData, (fixture) => <Fixture fixture={fixture} />) }
      </Stack>
    </Box>
  );
}

export default Fixtures;
