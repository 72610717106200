import { Box } from '@mui/material';
import React from 'react';
import './shared.css';

function Col({ data }) {
  return (
    <Box sx={{
      display: 'flex',
      // flexDirection: 'row',
      // alignItems: 'flex-end',
      justifyContent: 'flex-end',
      // backgroundColor: 'palegoldenrod',
      m: 0.5,
      width: 30,
      // minWidth: { md: 350 },
      // backgroundColor: 'mediumseagreen',
    }}
    >
      {data}
    </Box>
  );
}

function TableRow({
  row: {
    race,
    played,
    win,
    draw,
    loss,
    goalsFor,
    goalsAgainst,
    goalDiff,
    pts,
  },

}) {
  return (
    <Box sx={{
      display: 'flex',
      // flexDirection: 'row',
      // alignItems: 'center',
      // justifyContent: 'space-between',
      // backgroundColor: 'palegoldenrod',
      // m: 1,
    // minWidth: { md: 350 },
    }}
    >
      <Box sx={{
        display: 'flex',
        // m: 1,
        width: 120,
      }}
      >
        {race}
      </Box>
      <Col data={played} />
      <Col data={win} />
      <Col data={draw} />
      <Col data={loss} />
      <Col data={goalsFor} />
      <Col data={goalsAgainst} />
      <Col data={goalDiff} />
      <Col data={pts} />
    </Box>
  );
}

export default TableRow;
