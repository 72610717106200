import React, { useEffect, useState } from 'react';
import './shared.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import {
  groupBy, isEmpty, map, orderBy,
} from 'lodash';

import {
  Box, Button, Container, Divider, List, Stack,
} from '@mui/material';
import TeamRow from '../Components/TeamRow';
import Stars from '../Components/Stars';
import Rules from '../Components/Rules';
import ManagerCard from '../Components/ManagerCard';

function BB({
  teams, stars, rules, signin,
}) {
  const [teamsGrouped, setTeamsGrouped] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);

  useEffect(() => {
    if (!isEmpty(teams)) {
      setTeamsGrouped(groupBy(teams, (team) => team.owner));
    }
  }, [teams]);

  return (
    <>
      <Button onClick={signin}>
        Google Sign in
      </Button>
      {/* <Button onClick={test}>
        test Sign in
      </Button>
      <Button onClick={signout}>
        signout
      </Button> */}
      <h1>
        Teams
      </h1>
      <List
        direction="row"
        sx={{
          display: 'flex',
          // // justifyContent: 'space-between',
          // m: 0.5,
          // // backgroundColor: 'ThreeDFace',
          // maxWidth: 400,

        }}
        component={Stack}
        overflow="auto"
        // divider={<Divider />}
        // spacing={5}
      >
        {map(teamsGrouped, (data) => <ManagerCard data={data} setSelectedTeam={setSelectedTeam} selectedTeam={selectedTeam} />)}
      </List>
      <h1>
        Star Players
      </h1>
      <Stars teams={teams} stars={stars} selectedTeam={selectedTeam} />
      <h1>
        Rules
      </h1>
      <Rules rules={rules} />
    </>
  );
}

export default BB;
