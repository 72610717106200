import React from 'react';
import './shared.css';
import {
  compact, filter, intersection, isEmpty, map, orderBy,
} from 'lodash';
import { Box, Paper, Typography } from '@mui/material';

function Col({ data, title }) {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      width: 50,
    }}
    >
      <Typography variant="subtitle1" sx={{ color: title ? 'text.primary' : 'text.secondary', fontWeight: 600 }}>{data}</Typography>
    </Box>
  );
}

function Stars({ teams: playerTeams, stars, selectedTeam }) {
  const getAvailableTeams = (teams) => {
    if (!teams) {
      return 'Anyone';
    }
    // const teamList = teams.map((team) => {
    const teamList = map(playerTeams, (playerTeam) => {
      const included = intersection(playerTeam.special, teams);
      // console.log('===================');
      // console.log('playerTeam.name', playerTeam.race);

      // console.log('playerTeam.special', playerTeam.special);
      // // console.log('team', team)
      // console.log('included', included);
      if (!isEmpty(included)) return playerTeam.race;
      return null;
    });
    // })
    // console.log('teamList', compact(teamList))

    return compact(teamList).join(', ');
  };

  const isAvailableForTeam = (teams) => {
    if (!teams) {
      return true;
    }
    if (!selectedTeam) {
      return true;
    }
    const included = intersection(selectedTeam.special, teams);
    if (!isEmpty(included)) return true;
    return false;
  };

  return (
    <div className="App">
      {map(filter(orderBy(stars, ['cost'], ['asc']), (star) => isAvailableForTeam(star.teams)), ({
        cost, name, skills, ma, st, ag, av, pa, teams,
      }) => (
        <Paper
          sx={{
            m: 1,
            p: '1px',
          }}
          variant="outlined"
        >
          <Box sx={{
            display: 'flex',
            px: '5px',
            backgroundColor: '#990000',
          }}
          >
            <Typography
              variant="subtitle1"
              align="left"
              sx={{
                flexGrow: 1,
                width: 120,

              }}
              style={{ fontWeight: 600 }}
            >
              Name
            </Typography>
            <Col data="MA" title />
            <Col data="ST" title />
            <Col data="AG" title />
            <Col data="PA" title />
            <Col data="AV" title />
          </Box>
          <Box sx={{
            // display: 'flex',
            px: '5px',
            // flexDirection: 'row',
            // alignItems: 'center',
            // justifyContent: 'space-between',
            // backgroundColor: 'palegoldenrod',
            // m: 1,
            // minWidth: { md: 350 },
          }}
          >
            <Box sx={{
              display: 'flex',
              // flexDirection: 'row',
              // alignItems: 'center',
              // justifyContent: 'space-between',
              // backgroundColor: 'palegoldenrod',
              // m: 1,
              // minWidth: { md: 350 },

              marginBottom: 1,

            }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                sx={{
                  color: 'text.secondary',
                  flexGrow: 1,
                  fontWeight: 600,
                  width: 120,
                }}
              >
                {name}
              </Typography>
              <Col data={ma} />
              <Col data={st} />
              <Col data={ag} />
              <Col data={pa} />
              <Col data={av} />
            </Box>

            <Box sx={{
              display: 'flex',
              flexGrow: 1,
            }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                sx={{
                  color: 'text.secondary', flexGrow: 1,
                }}
                style={{ fontWeight: 600 }}
              >
                Skills and Traits
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary', fontWeight: 600 }}>
                {cost}
                K
              </Typography>
            </Box>
            <Typography
              variant="subtitle2"
              align="left"
              sx={{ color: 'text.secondary' }}
            >
              {skills}

            </Typography>

            <Box sx={{
              display: 'flex',
            }}
            >
              <Typography variant="subtitle1" align="left" sx={{ color: 'text.secondary', minWidth: 80 }} style={{ fontWeight: 600 }}>
                Plays for:
              </Typography>
              <Typography variant="subtitle1" align="left" sx={{ color: 'text.secondary' }}>
                {getAvailableTeams(teams)}
              </Typography>

            </Box>
          </Box>

        </Paper>
      ))}
    </div>
  );
}

export default Stars;
