import React from 'react';
import './shared.css';
import {
  map,
} from 'lodash';
import {
  Box, Card, Paper, Typography,
} from '@mui/material';

function Col({ data }) {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      m: 0.5,
      // backgroundColor: 'mediumseagreen',
    }}
    >
      {data}
    </Box>
  );
}

function Rules({ rules }) {
  return (
    <>
      {map(rules, ({ table, title }) => (
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            // justifyContent: 'space-between',
            // backgroundColor: 'palegoldenrod',
            m: 1,
            p: '1px',
            // minWidth: { md: 350 },
          }}
          variant="outlined"

        >
          <Typography
            variant="subtitle1"
            align="left"
            sx={{
              flexGrow: 1,
              backgroundColor: '#990000',
              fontWeight: 600,
              paddingLeft: 1,
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              // display: 'flex',
              // flexDirection: 'column',
              // backgroundColor: 'bisque',
              px: '5px',
            }}
          >
            {map(table, ({ roll, text, title: subTitle }) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                  // flexDirection: 'column',
                  // backgroundColor: 'bisque',
                  // m: 1,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    align="left"
                    sx={{
                      color: 'text.secondary',
                      minWidth: 47,
                      // fontWeight: 600,
                    }}
                  >
                    {roll}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    sx={{
                      flexGrow: 1,
                      // backgroundColor: '#990000',
                      color: 'text.secondary',
                      fontWeight: 600,
                    }}
                  >
                    {subTitle}
                  </Typography>
                </Box>
                <Typography
                  variant="body"
                  align="left"
                  sx={{
                    display: 'flex',
                    color: 'text.secondary',
                    minWidth: 50,
                    marginLeft: 6,
                    marginBottom: 1,
                  }}
                >
                  {text}
                </Typography>
              </>
            ))}
          </Box>

        </Card>

      ))}
    </>

  );
}

export default Rules;
