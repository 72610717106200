import React, { useEffect, useState } from 'react';
// import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from 'react-router-dom';
import { Launch } from '@mui/icons-material';

import {
  Box, Button, Container, createTheme, CssBaseline, ThemeProvider, Link as MuiLink,
} from '@mui/material';
import { initializeApp } from 'firebase/app';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import {
  getAuth, signInWithRedirect, GoogleAuthProvider, signOut,
} from 'firebase/auth';

import BB from './Containers/BB';
import Fixtures from './Containers/Fixtures';
import Table from './Containers/Table';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBntMoSn2jz_OoERZ0l0p-CHHZQISXdXMY',
  authDomain: 'thefixturecomputer.firebaseapp.com',
  projectId: 'thefixturecomputer',
  storageBucket: 'thefixturecomputer.appspot.com',
  messagingSenderId: '243899236089',
  appId: '1:243899236089:web:e402f7983cb3931630ac28',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const themeLight = createTheme({
  palette: {
    background: {
      default: '#e4f0e2',
    },
  },
});

const themeDark = createTheme({
  palette: {
    background: {
      default: '#222222',
    },
    text: {
      primary: '#ffffff',
      secondary: '#333',
    },
  },
});

function App() {
  const [light, setLight] = React.useState(false);
  const [teams, setTeams] = useState([]);
  const [stars, setStars] = useState([]);
  const [rules, setRules] = useState([]);
  const [fixtures, setFixtures] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  const signin = async () => {
    signInWithRedirect(auth, provider);
  };

  const test = () => {
    const user = auth.currentUser;
    console.log('test');

    if (user) {
      console.log('user:', user);

    // user is signed in, show user data
    } else {
      console.log('no user:');

    // user is signed out, show sign-in form
    }
  };

  const signout = () => {
    signOut(auth).then(() => {
      console.log('signout:', auth);

      // Sign-out successful.
    }).catch((error) => {
      console.log('signout error:', error);
    });
  };

  useEffect(() => {
    console.log('User data effect', auth.currentUser);

    const getTeams = async () => {
      // console.log('getting teams 3');
      const querySnapshot = await getDocs(collection(db, 'teams'));
      const arr = [];

      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });

      setTeams(arr);
    };

    if (currentUser) {
      getTeams();
    }
  }, [currentUser]);

  useEffect(() => {
    const getStars = async () => {
      // console.log('getting getStars');
      const querySnapshot = await getDocs(collection(db, 'stars'));
      const arr = [];

      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      setStars(arr);
    };

    if (currentUser) {
      getStars();
    }
  }, [currentUser]);

  useEffect(() => {
    const getRules = async () => {
      console.log('getting getRules');
      const querySnapshot = await getDocs(collection(db, 'rules'));
      const arr = [];

      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });

      setRules(arr);
    };

    if (currentUser) {
      getRules();
    }
  }, [currentUser]);

  useEffect(() => {
    const getFixtures = async () => {
      // console.log('getting fixtures');
      const querySnapshot = await getDocs(collection(db, 'fixtures'));
      const arr = [];

      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });

      setFixtures(arr);
    };

    if (currentUser) {
      getFixtures();
    }
  }, [currentUser]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  return (
    <ThemeProvider theme={light ? themeLight : themeDark}>
      <CssBaseline />
      <Router>
        <Container maxWidth="sm">
          <Box sx={{
            display: 'flex',
            // my: 4,
            justifyContent: 'space-between',
            // backgroundColor: 'lightgrey',
          }}
          >
            <Button to="/" variant="contained" LinkComponent={Link}>
              BB
            </Button>
            <Button to="/fixtures" variant="contained" LinkComponent={Link}>
              fixtures
            </Button>
            <Button to="/table" variant="contained" LinkComponent={Link}>
              table
            </Button>
            {/* <Button variant="contained"> */}
            {/* <Link to={'to="'}>stuff</Link> */}
            <Button variant="contained" endIcon={<Launch />}>
              <MuiLink color="white" href="https://docs.google.com/spreadsheets/d/186rEIVV93wx2gK4fvGKB7_SUXwSeLZfHD5zUWHvuk88/edit?usp=sharing" underline="none">
                Teams
              </MuiLink>
            </Button>
          </Box>
          {/* </Button> */}
          <Routes>
            <Route path="/" element={<BB teams={teams} stars={stars} rules={rules} signin={signin} />} />
            <Route path="/fixtures" element={<Fixtures teams={teams} fixtures={fixtures} />} />
            <Route path="/table" element={<Table teams={teams} fixtures={fixtures} />} />
          </Routes>
        </Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;
